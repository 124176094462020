@font-face {
  font-family: "Montserrat", sans-serif;
}

@font-face {
  font-family: "Roboto Mono";
  font-weight: 700;
  src: url("../../assets/fonts/RobotoMono-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Mono";
  font-weight: 600;
  src: url("../../assets/fonts/RobotoMono-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Mono";
  font-weight: 500;
  src: url("../../assets/fonts/RobotoMono-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Mono";
  font-weight: 400;
  src: url("../../assets/fonts/RobotoMono-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Mono";
  font-weight: 300;
  src: url("../../assets/fonts/RobotoMono-Thin.ttf") format("truetype");
}
