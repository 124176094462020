.App {
  text-align: center;
}

.row {
  /* 꽉 차지않는 row */
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: row;
}

.f-row {
  /* 부모에 꽉 차는 row */
  width: 100%;
  display: flex;
  flex-direction: row;
}

.column {
  /* 꽉 차지않는 column */
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
}

.f-column {
  /* 부모에 꽉 차는 column */
  width: 100%;
  display: flex;
  flex-direction: column;
}

.j-center {
  justify-content: center;
}

.j-start {
  justify-content: start;
}

.j-end {
  justify-content: end;
}

.a-center {
  align-items: center;
}

.a-start {
  align-items: start;
}

.a-end {
  justify-content: end;
}

.margin-side {
  margin: 0px 30px 0px 30px;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
