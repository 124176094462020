/* @import url("../../../../utils/styles/colors.js") COLORS; */

.myDIV {
  background-color: #ddd;
  transition: 3s;
}

.button-container2 {
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
  position: relative;
  border-radius: 30px;
  border: 2px solid #5552ff;
  height: 41px;
}

ul.bankBox {
  padding-inline-start: 0px;
  /* margin-top: 10px; */
  /* padding: auto 10px; */
}

ul li.bankBox {
  display: inline;
  text-align: center;
}

li > button.bankBox {
  display: inline-block;
  width: 50%;
  /* padding: 0.75rem 0; */
  margin: 0;
  text-decoration: none;
  color: #5552ff;
  font-weight: 600;
  font-size: min(2.2vh, 16px);
  background-color: transparent;
  border: 0;
  position: relative;
  z-index: 2;
  min-height: 37px;
  margin-top: -10px;
}

hr.bankBox {
  height: 37px;
  width: min(56%);
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
  margin: 0;
  background: #5552ff;
  border: none;
  border-radius: 24px;
  transition: 0.3s ease-in-out;
}
