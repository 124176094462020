/* @import url("../../../../utils/styles/colors.js") COLORS; */

* {
  box-sizing: border-box;
}

body {
  font: 300 100% "Helvetica Neue", Helvetica, Arial;
}

.hide {
  display: none;
  /* transition: all ease 2s 0s; */
}

.myDIV {
  background-color: #ddd;
  transition: 3s;
}

.myDIV:hover + .hide {
  display: block;
  color: red;
  background-color: rgba(255, 255, 255, 0.95);
  pointer-events: none;
}

div.hide > button.learn {
  background-color: #e31b6d;
  color: #ffffff;
}

button.learn:hover {
  background-color: #e31b6d;
  color: #ffffff;
}

.button-container {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  position: relative;
  border-radius: 30px;
  border: 1px solid #FFFFFF;
  height: 41px;
}

ul {
  padding-inline-start: 0px;
  margin-top: 10px;
  padding: auto 10px;
}

ul li {
  display: inline;
  text-align: center;
}

li > button {
  display: inline-block;
  width: 50%;
  /* padding: 0.75rem 0; */
  margin: 0;
  text-decoration: none;
  color: #FFFFFF;
  font-weight: 600;
  font-size: min(2.2vh, 16px);
  background-color: transparent;
  border: 0;
  position: relative;
  z-index: 2;
}

hr {
  height: 25px;
  width: min(50%);
  position: absolute;
  top: 7px;
  left: 7px;
  z-index: 0;
  margin: 0;
  background: white;
  border: none;
  border-radius: 24px;
  transition: 0.3s ease-in-out;
}
